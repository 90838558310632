var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("context-title", {
        attrs: {
          passedActions: _vm.titleBarActions,
          spaceBetween: false,
          title: _vm.title,
        },
      }),
      _c(
        "v-container",
        { staticClass: "fill-height pa-0", attrs: { fluid: "" } },
        [
          _c("span", { staticClass: "font-italic ml-5" }, [
            _vm._v(" " + _vm._s(_vm.hintText) + " "),
          ]),
          _c(
            "v-row",
            { staticClass: "ma-0" },
            [
              _vm.noteToEdit
                ? _c(
                    "v-col",
                    {
                      staticClass: "ml-3 mr-3 mt-3 mb-0 pa-0",
                      attrs: { md: "12" },
                    },
                    [
                      _c(
                        "v-card",
                        { staticClass: "card-editing" },
                        [
                          _c(
                            "v-card-text",
                            [
                              _c("v-textarea", {
                                attrs: {
                                  "hide-details": "",
                                  solo: "",
                                  name: "input-7-4",
                                  label: "Note",
                                },
                                model: {
                                  value: _vm.noteToEdit.content,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.noteToEdit, "content", $$v)
                                  },
                                  expression: "noteToEdit.content",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-card-actions",
                            [
                              _c(
                                "v-row",
                                { staticClass: "name-row pt-1 ml-2 pb-3" },
                                [
                                  _c("div", [
                                    _vm._v(
                                      _vm._s(_vm.footerString(_vm.noteToEdit))
                                    ),
                                  ]),
                                  _c("div", {
                                    staticClass: "row--cancel",
                                    on: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                        return _vm.onCancelEdit.apply(
                                          null,
                                          arguments
                                        )
                                      },
                                    },
                                  }),
                                  _c("div", {
                                    staticClass: "row--save",
                                    on: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                        return _vm.onSaveItem(_vm.noteToEdit)
                                      },
                                    },
                                  }),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._l(_vm.computedNotes, function (note) {
                return _c(
                  "v-col",
                  {
                    key: note.id,
                    staticClass: "ml-3 mr-3 mt-3 mb-0 pa-0",
                    attrs: { md: "12" },
                  },
                  [
                    _c(
                      "v-card",
                      [
                        _c("v-card-text", [_vm._v(_vm._s(note.content))]),
                        _c(
                          "v-card-actions",
                          [
                            _c(
                              "v-row",
                              { staticClass: "name-row pt-1 ml-2 pb-3" },
                              [
                                _c("div", [
                                  _vm._v(
                                    _vm._s(note.author) +
                                      " - " +
                                      _vm._s(
                                        _vm.timestampString(note.timestamp)
                                      )
                                  ),
                                ]),
                                !_vm.noteToEdit
                                  ? _c("div", {
                                      staticClass: "row--edit",
                                      on: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                          return _vm.onEditItem(note)
                                        },
                                      },
                                    })
                                  : _vm._e(),
                                _c("div", {
                                  staticClass: "row--delete",
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.onDeleteItem(note)
                                    },
                                  },
                                }),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              }),
            ],
            2
          ),
        ],
        1
      ),
      _vm.deleteConfirmationModal
        ? _c(
            "EditingModal",
            { attrs: { size: "sm" } },
            [
              _c(
                "AlertDialog",
                {
                  attrs: {
                    title: "Confirm Delete",
                    onExit: () => (_vm.deleteConfirmationModalID = false),
                    actions: _vm.deleteConfirmationActions,
                  },
                },
                [_vm._v("Are you sure you want to delete this note?")]
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }