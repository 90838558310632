<template>
  <div>
    <context-title :passedActions="titleBarActions" :spaceBetween="false" :title="title" />
    <v-container class="fill-height pa-0" fluid>
      <span class="font-italic ml-5"> {{ hintText }} </span>
      <v-row class="ma-0">
        <v-col class="ml-3 mr-3 mt-3 mb-0 pa-0" md="12" v-if="noteToEdit">
          <v-card class="card-editing">
            <v-card-text>
              <v-textarea
                hide-details
                solo
                name="input-7-4"
                label="Note"
                v-model="noteToEdit.content"
              ></v-textarea>
            </v-card-text>
            <v-card-actions>
              <v-row class="name-row pt-1 ml-2 pb-3">
                <div>{{ footerString(noteToEdit) }}</div>
                <div class="row--cancel" @click.stop="onCancelEdit"></div>
                <div class="row--save" @click.stop="onSaveItem(noteToEdit)"></div>
              </v-row>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col class="ml-3 mr-3 mt-3 mb-0 pa-0" md="12" v-for="note in computedNotes" :key="note.id">
          <v-card>
            <v-card-text>{{ note.content}}</v-card-text>
            <v-card-actions>
              <v-row class="name-row pt-1 ml-2 pb-3">
                <div>{{note.author}} - {{ timestampString(note.timestamp) }}</div>
                <div v-if="!noteToEdit" class="row--edit" @click.stop="onEditItem(note)"></div>
                <div class="row--delete" @click.stop="onDeleteItem(note)"></div>
              </v-row>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <EditingModal v-if="deleteConfirmationModal" size="sm">
      <AlertDialog
        title="Confirm Delete"
        :onExit="() => deleteConfirmationModalID = false"
        :actions="deleteConfirmationActions"
      >Are you sure you want to delete this note?</AlertDialog>
    </EditingModal>
  </div>
</template>

<script>
import ContextTitle from "../components/context_title.vue";
import EditingModal from "components/editing_modal.vue";
import AlertDialog from "components/alert_dialog.vue";

import moment from "moment";

import { mapGetters, mapActions } from "vuex";
export default {
    name: "Notes",
    components: {
        ContextTitle,
        EditingModal,
        AlertDialog
    },
    props: [
        'title', 'noteType', 'hintText'
    ],
    data() {
        return {
            noteToEdit: false,
            deleteConfirmationModal: false,
            deleteConfirmationActions: [
                {
                    type: "cancel",
                    display: "Cancel",
                    run: () => {
                        this.deleteConfirmationModal = false;
                    }
                },
                {
                    type: "remove-red",
                    display: "Yes, delete",
                    run: this.confirmDelete
                }
            ]
        };
    },
    computed: {
        computedNotes() {
            var notes = []
            if (this.noteType == 'STORE') {
                notes = this.storeNotes
            } else if (this.noteType == "POS") {
                notes = this.posNotes
            }
            if (this.noteToEdit && this.noteToEdit.id) {
                return notes.filter(el => el.id != this.noteToEdit.id)
            }
            return notes
        },
        titleBarActions() {
            if (this.noteToEdit) return [];
            return [
                {
                    type: "add-new",
                    display: "New",
                    run: this.addNew
                }
            ];
        },
        ...mapGetters(["user"]),
        ...mapGetters("NoteStore", ["storeNotes", "posNotes"])
    },
    methods: {
        timestampString(timestamp) {
            return moment(timestamp).fromNow();
        },
        footerString(note) {
            var string = `${note.author}`;
            return string;
        },
        onEditItem(note) {
            this.noteToEdit = note;
        },
        onDeleteItem(note) {
            this.deleteConfirmationModal = note.id;
            this.noteToEdit = false;
        },
        onCancelEdit(note) {
            this.noteToEdit = false;
        },
        onSaveItem(note) {
            let store = this.$route.params.store;

            var payload = {
                storeWaypointID: store,
                noteType: this.noteType,
                note
            };
      
            if (note.id) {
                payload.note.timestamp = moment().valueOf()
                payload.note.author = this.user.email
                this.updateNote(payload).then(() => {
                    this.noteToEdit = false;
                })
            } else {
                this.createNote(payload).then(() => {
                    this.noteToEdit = false;
                });
            }
        },
        confirmDelete() {
            let storeWaypointID = this.$route.params.store
            let noteID = this.deleteConfirmationModal
            this.deleteConfirmationModal = false
            this.deleteNote({
                storeWaypointID,
                noteType: this.noteType,
                noteID
            })
        },
        addNew() {
            this.noteToEdit = {
                content: "",
                author: this.user.email,
                timestamp: moment().valueOf()
            };
        },
        ...mapActions("NoteStore", [
            "getNotes",
            "createNote",
            "updateNote",
            "deleteNote"
        ])
    },
    mounted() {
        let store = this.$route.params.store;
        let payload = {
            storeWaypointID: store,
            noteType: this.noteType,
        }
        this.getNotes(payload);
    }
};
</script>

<style lang="scss" scoped>
@import "~scss/variables";
@import "~scss/mixins";

.name-row {
  color: $brand_color;
  font-style: italic;
}
.card-editing-title {
  color: $warn_yellow;
}
.card-editing {
  border: 1px solid $warn_yellow;
}
.row {
  &__title {
    padding-left: spacing(xs);
  }

  &--delete {
    @include background-image("", "delete.svg", "../img/");
    background-size: 20px;
    cursor: pointer;
    width: 20px;
    height: 20px;
    margin-top: 2px;
    margin-left: 1px;
  }
  &--edit {
    @include background-image("", "edit_logo.svg", "../img/");
    background-size: 14px;
    cursor: pointer;

    margin-left: 10px;
    width: 20px;
    height: 20px;
    padding: 5px;
    margin-top: 3px;
  }
  &--cancel {
    @include background-image("", "cancel_logo.svg", "../img/");
    background-size: 14px;
    cursor: pointer;

    margin-left: 10px;
    width: 20px;
    height: 20px;
    padding: 5px;
    margin-top: 5px;
  }
  &--save {
    @include background-image("", "check_green.svg", "../img/");
    background-size: 14px;
    cursor: pointer;

    margin-left: 10px;
    width: 20px;
    height: 20px;
    padding: 5px;
    margin-top: 5px;
  }
}
</style>
